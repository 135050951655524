<template>
  <v-row>
    <v-col cols="12" sm="4">
      <v-switch
        v-bind="$attrs"
        v-model="value.active"
        :label="label"
        :hint="hint"
        :persistent-hint="hint ? true : false"
        @change="change"
      ></v-switch>
    </v-col>
    <v-expand-transition>
      <v-col v-if="value.active" cols="12" sm="8">
        <v-row>
          <slot
            :disabled="!value.active"
            :param="value.param"
            :errors="$utils.getIfExists(errorMessages, ['param'])"
            @change="change"
          ></slot>
        </v-row>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col v-if="value.active" cols="12">
        <v-row>
          <slot
            name="below"
            :disabled="!value.active"
            :param="value.param"
            :errors="$utils.getIfExists(errorMessages, ['param'])"
            @change="change"
          ></slot>
        </v-row>
        <v-divider v-if="!hideDivider"></v-divider>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
export default {
  name: "InputToggleParam",
  components: {},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        active: false,
        param: {},
      }),
    },
    label: {
      type: String,
      required: true,
    },
    hint: { type: String, required: false, default: null },
    hideDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {
    if (!this.value.param || typeof this.value.param === "undefined") {
      this.$set(this.value, "param", {});
    }
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
    change() {
      this.$emit("change");
      this.$emit("input", this.value);
    },
  },
};
</script>

<style></style>
